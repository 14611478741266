<template>
  <div class="footer">
    <div class="reserved-rights">{{ $t('general.copyright') }}</div>
    <div>
      <ul>
        <li><b-link class="link-terms-conditions" @click="showModal('terms-modal')">{{ $t('general.terms') }}</b-link></li>
        <li><b-link class="link-privacy-policy" @click="showModal('privacy-modal')">{{ $t('general.privacy') }}</b-link></li>
      </ul>
    </div>

    <!-- Terms modal -->
    <terms-modal></terms-modal>

    <!-- Privacy modal -->
    <privacy-modal></privacy-modal>

  </div>
</template>

<style lang="scss" scoped>
@import "./BaseFooter";
</style>

<script>
import bus from '@/api/bus';

export default {
  components: {
    TermsModal: () => import('@/components/Modals/Terms/Terms.vue'),
    PrivacyModal: () => import('@/components/Modals/Privacy/Privacy.vue'),
  },

  created() {
    bus.$on('showModal', (modal) => {
      this.showModal(modal);
    });
  },

  methods: {
    showModal(modal) {
      this.$bvModal.show(modal);
    },
  },
};
</script>
